import { DrupalNode } from "next-drupal"

import { Layout } from "components/layout"
import {Europe1RssFeed} from "@/components/europe1";
import {AvisDecesBannerComponent} from "@/components/banner"
import {ProgrammationRadioLive} from "@/components/programmation";
import Container from "@/components/ui/container";
import {Ads} from "@/components/ui/ads";
import {
  getArticlePodcastHighlight,
  getFavorites, getLatestArticle, getLatestPodcast,
  getPromotedPodcasts,
  getTeamMembers,
  getUpcomingPodcasts
} from "@/lib/drupalFetcher";
import {LatestPodcast, UpcomingPodcast} from "@/components/podcast";
import {ArticlesFeed, LatestNodes} from "@/components/articles";
import {GetStaticPropsResult, Metadata} from "next";
import {SliderFavorites} from "@/components/favorites";
import {EquipeSlider} from "@/components/equipe";
import {HeadMetadata} from "@/components/headMetadata";
import React from "react";
import {drupal} from "@/lib/drupal";

interface IndexPageProps {
  nodesPromoted: DrupalNode[]
  nodesUpcoming: DrupalNode[]
  favoritesNodes: DrupalNode[]
  teamMembers: DrupalNode[]
  lastestArticle: DrupalNode[]
  lastestPodcast: DrupalNode[]
  articlePodcastHighlight: DrupalNode[]
}

export default function IndexPage({nodesPromoted, nodesUpcoming, favoritesNodes, teamMembers, lastestArticle, lastestPodcast, articlePodcastHighlight}: IndexPageProps) {
  const articles = drupal.deserialize(lastestArticle);
  const highlight = drupal.deserialize(articlePodcastHighlight)
  const nodes = [...articles as [], ...drupal.deserialize(lastestPodcast) as []];

  return (
    <Layout>
      <HeadMetadata
        description="Les fréquences aux couleurs de la France en Nouvelle Calédonie. La radio d’actualité basée à Nouméa pour être les premiers et les mieux informés."
      />

      <Container className="pb-8">
        <LatestNodes nodes={nodes} highlight={highlight} />
      </Container>

      {/*<Container>*/}
      {/*  <div className='py-8 w-fit mx-auto'>*/}
      {/*    <Ads adsId="53" dimension={'728090'} className='hidden lg:block' />*/}
      {/*    <Ads adsId="53" dimension={'320050'} className='block lg:hidden' />*/}
      {/*  </div>*/}
      {/*</Container>*/}

      <ProgrammationRadioLive/>
      <UpcomingPodcast nodes={nodesUpcoming} />
      <LatestPodcast seeAllBtn={true} nodes={nodesPromoted} />
      <Container className="py-8">
        <div className="lg:grid grid-flow-col gap-6">
          <div className="col-span-2 lg:mb-0 mb-8">
            <ArticlesFeed articles={articles}/>
            <Europe1RssFeed/>
            <AvisDecesBannerComponent/>
          </div>

          <aside>
            <div className="sticky top-28 col-span-1 text-center">
              <Ads adsId='54' dimension={'300600'} className='hidden mb-4 lg:inline-block'/>
              <Ads adsId='55' dimension={'300250'} className='hidden lg:inline-block' />
            </div>
          </aside>
        </div>
      </Container>
      <SliderFavorites nodes={favoritesNodes}/>
      <EquipeSlider nodes={teamMembers} />
      <Container className="py-8">
        <div className="text-center">
          <Ads adsId="101" dimension={'300600'} className="lg:hidden" />
          <br/>
          <Ads adsId="2" dimension={'300250'} className="lg:hidden" />
        </div>
      </Container>
    </Layout>
  )
}

export async function getServerSideProps(context): Promise<GetStaticPropsResult<IndexPageProps>> {
  context.res.setHeader(
    'Cache-Control',
    'public, s-maxage=60, max-age=60'
  )
  const nodesPromoted: DrupalNode[] = await getPromotedPodcasts(context);
  const nodesUpcoming: DrupalNode[] = await getUpcomingPodcasts(context, 2);
  const favoritesNodes: DrupalNode[] = await getFavorites(context);
  const teamMembers: DrupalNode[] = await getTeamMembers(context);
  const lastestArticle: DrupalNode[] = await getLatestArticle(context);
  const lastestPodcast: DrupalNode[] = await getLatestPodcast(context);
  const articlePodcastHighlight : DrupalNode[] = await getArticlePodcastHighlight();
  
  return {
    props: {
      nodesPromoted,
      nodesUpcoming,
      favoritesNodes,
      teamMembers,
      lastestArticle,
      lastestPodcast,
      articlePodcastHighlight
    }
  }
}
